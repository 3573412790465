import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../components/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import Company1 from '../../../assets/logos/company1.png';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import AuthContext from '../../../contexts/authContext';
import FileIDReader from '../../File/FileIDReader';

const ProductListHeader = () => {

	const { userData } = useContext(AuthContext)

	return (
		<Header>
			<HeaderLeft>
				<div
					className='col d-flex align-items-center cursor-pointer gap-2'
					role='presentation'
				>
					<div className='position-relative'>
						{
							<FileIDReader avatar id={userData?.image} size={48} />
						}
					</div>
					<div className='me-3'>
						<div>
							<div className='fw-bold fs-6 mb-0'>
								{userData!.name}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default ProductListHeader;
