import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import FileManagerProvider from '../../contexts/FileManagerContext';
import WebsitePagesProvider from '../../contexts/websitePagesContext';
import AuthRoute from '../../pages/auth/AuthRoute';
import ProtectedRoute from '../../pages/auth/ProtectedRoute';
import contents, { websitePages, auth } from '../../routes/contentRoutes';
import FileManagerModal from '../WebsitePages/FooterPages/FileManagerModal';

const ContentRoutes = () => {
	return (
		<FileManagerProvider>
			<Routes>
				{auth.map((page) => (
					<Route
						key={page.path}
						{...page}
						element={<AuthRoute>{page.element}</AuthRoute>}
					/>
				))}
				{contents.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route
						key={page.path}
						{...page}
						element={
							<ProtectedRoute>
								<>
									{page.element}
									<FileManagerModal />
								</>
							</ProtectedRoute>
						}
					/>
				))}
				{websitePages.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route
						key={page.path}
						{...page}
						element={
							<ProtectedRoute>
								<>
									{page.element}
									<FileManagerModal />
								</>
							</ProtectedRoute>
						}
					/>
				))}
			</Routes>
		</FileManagerProvider>
	);
};

export default ContentRoutes;
