import { LazyMotion } from 'framer-motion';
import React, { lazy } from 'react';
import UserProvider from '../contexts/userContext';
import { demoPages, dashboardMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
};

const DASHBOARD = {
	DASHBOARD_MAIN: lazy(() => import('../pages/dashboard')),
};

const USER = {
	USERS_LIST: lazy(() => import('../pages/users/UsersList')),
	BLACK_LIST: lazy(() => import('../pages/users/TheBlacklist')),
	UPDATE_USER: lazy(() => import('../pages/users/UpdateUser')),
	USER_DETAILS: lazy(() => import('../pages/users/UserDetails')),
};

const COMPANY = {
	CREATE_COMPANY: lazy(() => import('../pages/companies/CreateCompany')),
	COMPANIES_LIST: lazy(() => import('../pages/companies/CompaniesList')),
	UPDATE_COMPANY: lazy(() => import('../pages/companies/UpdateCompany')),
};

const CATEGORY = {
	CREATE_CATEGORY: lazy(() => import('../pages/categories/CreateCategory')),
	CATEGORIES_LIST: lazy(() => import('../pages/categories/CategoriesList')),
	CATEGORY_DETAILS: lazy(() => import('../pages/categories/Category')),
};

const VEHICLE_TYPE = {
	CREATE_VEHICLE_TYPE: lazy(() => import('../pages/vehicles-types/CreateVehicle')),
	VEHICLE_TYPES_LIST: lazy(() => import('../pages/vehicles-types/VehiclesList')),
	UPDATE_VEHICLE_TYPE: lazy(() => import('../pages/vehicles-types/EditVehicle')),
};

const SERVICE = {
	CREATE_SERVICE: lazy(() => import('../pages/services/CreateService')),
	SERVICE_DETAILS: lazy(() => import('../pages/services/ServiceDetails')),
	SERVICE_LIST: lazy(() => import('../pages/services/ServicesList')),
};

const SUGGESTION = {
	CREATE_SUGGESTION: lazy(() => import('../pages/suggestions/CreateSuggestion')),
	UPDATE_SUGGESTION: lazy(() => import('../pages/suggestions/UpdateSuggetion')),
	SUGGESTIONS_LIST: lazy(() => import('../pages/suggestions/SuggestionsList')),
};

const GATE = {
	CREATE_GATE: lazy(() => import('../pages/gates/CreateGate')),
	GATE_DETAILS: lazy(() => import('../pages/gates/EditGate')),
	GATES_LIST: lazy(() => import('../pages/gates/GatesList')),
};

const BOOKING = {
	CREATE_BOOKING: lazy(() => import('../pages/booking/CreateBooking')),
	BOOKING_DETAILS: lazy(() => import('../pages/booking/BookingDetails')),
	UPDATE_BOOKING: lazy(() => import('../pages/booking/UpdateBooking')),
	BOOKINGS_LIST: lazy(() => import('../pages/booking/BookingList')),
};

const TAX = {
	CREATE_TAX: lazy(() => import('../pages/taxes/CreateTax')),
	TAXES_LIST: lazy(() => import('../pages/taxes/TaxesList')),
	UPDATE_TAX: lazy(() => import('../pages/taxes/UpdateTax')),
};

const PAGES = {
	LIST_PAGES: lazy(() => import('../pages/website-pages/PagesList')),
	Create_Page: lazy(() => import('../pages/website-pages/CreatePage')),
	HOME: lazy(() => import('../pages/website-pages/Home')),
	ABOUT_US: lazy(() => import('../pages/website-pages/AboutUs')),
	BilTuning: lazy(() => import('../pages/website-pages/BilTuning')),
	CONTACT_US: lazy(() => import('../pages/website-pages/ContactUs')),
	FOLIE: lazy(() => import('../pages/website-pages/Folie')),
	GALLERY: lazy(() => import('../pages/website-pages/Gallery')),
	OPPRETTING_AND_LAKKERING: lazy(() => import('../pages/website-pages/OpprettingAndLakkering')),
	OUR_SERVICES: lazy(() => import('../pages/website-pages/OurServices')),
	SHOP: lazy(() => import('../pages/website-pages/Shop')),
	TERMS_AND_POLICY: lazy(() => import('../pages/website-pages/TermsAndPolicy')),
	Create_Header: lazy(() => import('../pages/website-pages/CreateHeader')),
	Create_Footer: lazy(() => import('../pages/website-pages/CreateFooter')),
};

const SETTINGS = {
	CONTACT_INFO: lazy(() => import('../pages/settings/ContactInfo')),
	ABOUT_WEBSITE: lazy(() => import('../pages/settings/AboutWebsite')),
	PAYMENT_DETAILS: lazy(() => import('../pages/settings/PaymentDetails')),
	BOOKING_SETTING: lazy(() => import('../pages/settings/BookingSetting')),
	ABOUT_USERS: lazy(() => import('../pages/settings/AboutUsers')),
	NOTIFICATION_MANAGMENT: lazy(() => import('../pages/settings/NotificationManagment')),
	ACCOUNT_MANAGER: lazy(() => import('../pages/settings/AccountManager')),
	SUPERVISORS: lazy(() => import('../pages/settings/Supervisors')),
	REPORTS: lazy(() => import('../pages/settings/Reports')),
	SUBSCRIPTION: lazy(() => import('../pages/settings/Subscription')),
	ROLES: lazy(() => import('../pages/settings/Roles')),
	PERMISSIONS: lazy(() => import('../pages/settings/Permissions')),
	WORKSHOP_SETTINGS: lazy(() => import('../pages/settings/WorkshopSettings')),
	TRANSLATE: lazy(() => import('../pages/settings/Translate')),
};

const TRACKINGS = {
	TRACKINGS_LIST: lazy(() => import('../pages/trackings/TrackingsList'))
}

const MESSAGES = {
	MESSAGES_LIST: lazy(() => import('../pages/messages/MessagesList')),
	MESSAGE_DETAILS: lazy(() => import('../pages/messages/MessageDetails'))
};



const FILES = {
	FILE: lazy(() => import('../pages/File/FileManager')),
};

const TRANSLATE = {
	LANGUAGES: lazy(() => import('../pages/translate/LanguagesList')),
	NEW_LANGUAGES: lazy(() => import('../pages/translate/AddLanguage')),
	LANGUAGE_WORDS: lazy(() => import('../pages/translate/LanguageWords')),
	EDIT_LANGUAGE: lazy(() => import('../pages/translate/EditLanguage')),
}

const presentation = [
	/**
	 * Dashboard
	 */

	{
		path: dashboardMenu.dashboard.path,
		element: <DASHBOARD.DASHBOARD_MAIN />,
		exact: true,
	},

	/**
	 * Users
	 */

	// {
	// 	path: demoPages.users.subMenu.createUser.path,
	// 	element: <USER.CREATE_USER />,
	// 	exact: true,
	// },
	{
		path: '/users/:id/update',
		element: <USER.UPDATE_USER />,
		exact: true,
	},
	{
		path: '/users/:id',
		element: <USER.USER_DETAILS />,
		exact: true,
	},
	{
		path: demoPages.users.subMenu.usersList.path,
		element: <USER.USERS_LIST />,
		exact: true,
	},
	{
		path: demoPages.users.subMenu.usersBlacklist.path,
		element: <USER.BLACK_LIST />,
		exact: true,
	},

	/**
	 * Companies
	 */

	{
		path: '/companies/:id',
		element: <COMPANY.UPDATE_COMPANY />,
		exact: true,
	},
	{
		path: demoPages.companies.subMenu.companiesList.path,
		element: <COMPANY.COMPANIES_LIST />,
		exact: true,
	},
	{
		path: demoPages.companies.subMenu.createCompany.path,
		element: <COMPANY.CREATE_COMPANY />,
		exact: true,
	},

	/**
	 * Categories
	 */

	{
		path: demoPages.categories.subMenu.createCategory.path,
		element: <CATEGORY.CREATE_CATEGORY />,
		exact: true,
	},
	{
		path: demoPages.categories.subMenu.categoriesList.path,
		element: <CATEGORY.CATEGORIES_LIST />,
		exact: true,
	},
	{
		path: '/categories/:id',
		element: <CATEGORY.CATEGORY_DETAILS />,
		exact: true,
	},

	/**
	 * Vehicle types
	 */

	{
		path: demoPages.vehicles.subMenu.createVehicleType.path,
		element: <VEHICLE_TYPE.CREATE_VEHICLE_TYPE />,
		exact: true,
	},

	{
		path: demoPages.vehicles.subMenu.vehiclesList.path,
		element: <VEHICLE_TYPE.VEHICLE_TYPES_LIST />,
		exact: true,
	},

	{
		path: '/vehicles-types/:id',
		element: <VEHICLE_TYPE.UPDATE_VEHICLE_TYPE />,
		exact: true,
	},

	/**
	 * SERVICES
	 */

	{
		path: demoPages.services.subMenu.createService.path,
		element: <SERVICE.CREATE_SERVICE />,
		exact: true,
	},
	{
		path: '/services/:id',
		element: <SERVICE.SERVICE_DETAILS />,
		exact: true,
	},
	{
		path: demoPages.services.subMenu.servicesList.path,
		element: <SERVICE.SERVICE_LIST />,
		exact: true,
	},

	/**
	 * Suggestions
	 */

	{
		path: demoPages.suggestion.subMenu.createSuggestion.path,
		element: <SUGGESTION.CREATE_SUGGESTION />,
		exact: true,
	},

	{
		path: '/suggestions/:id',
		element: <SUGGESTION.UPDATE_SUGGESTION />,
		exact: true,
	},

	{
		path: demoPages.suggestion.subMenu.suggestionsList.path,
		element: <SUGGESTION.SUGGESTIONS_LIST />,
		exact: true,
	},

	/**
	 * Gates
	 */

	{
		path: demoPages.gates.subMenu.createGate.path,
		element: <GATE.CREATE_GATE />,
		exact: true,
	},
	{
		path: '/gates/:id',
		element: <GATE.GATE_DETAILS />,
		exact: true,
	},
	{
		path: demoPages.gates.subMenu.gatesList.path,
		element: <GATE.GATES_LIST />,
		exact: true,
	},

	/**
	 * Booking
	 */

	{
		path: demoPages.booking.subMenu.createBooking.path,
		element: <BOOKING.CREATE_BOOKING />,
		exact: true,
	},

	{
		path: demoPages.booking.subMenu.bookingList.path,
		element: <BOOKING.BOOKINGS_LIST />,
		exact: true,
	},

	{
		path: '/booking/:id',
		element: <BOOKING.BOOKING_DETAILS />,
		exact: true,
	},

	{
		path: '/booking/:id/update',
		element: <BOOKING.UPDATE_BOOKING />,
		exact: true,
	},

	/**
	 * Tax
	 */

	{
		path: demoPages.tax.subMenu.createTax.path,
		element: <TAX.CREATE_TAX />,
		exact: true,
	},

	{
		path: demoPages.tax.subMenu.taxesList.path,
		element: <TAX.TAXES_LIST />,
		exact: true,
	},

	{
		path: '/taxes/:id',
		element: <TAX.UPDATE_TAX />,
		exact: true,
	},

	/**
	 * Settings
	 */

	{
		path: demoPages.settings.subMenu.contactInfo.path,
		element: <SETTINGS.CONTACT_INFO />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.aboutWebsite.path,
		element: <SETTINGS.ABOUT_WEBSITE />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.paymentDetails.path,
		element: <SETTINGS.PAYMENT_DETAILS />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.bookingSetting.path,
		element: <SETTINGS.BOOKING_SETTING />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.aboutUsers.path,
		element: <SETTINGS.ABOUT_USERS />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.notificationManagment.path,
		element: <SETTINGS.NOTIFICATION_MANAGMENT />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.accountManager.path,
		element: <SETTINGS.ACCOUNT_MANAGER />,
		exact: true,
	},

	{
		path: demoPages.settings.subMenu.supervisors.path,
		element: <SETTINGS.SUPERVISORS />,
		exact: true,
	},

	// {
	// 	path: demoPages.settings.subMenu.reports.path,
	// 	element: <SETTINGS.REPORTS />,
	// 	exact: true
	// },

	{
		path: demoPages.subscription.path,
		element: <SETTINGS.SUBSCRIPTION />,
		exact: true
	},

	// {
	// 	path: demoPages.settings.subMenu.translate.path,
	// 	element: <SETTINGS.TRANSLATE />,
	// 	exact: true,
	// },

	// {
	// 	path: demoPages.settings.subMenu.roles.path,
	// 	element: <SETTINGS.ROLES />,
	// 	exact: true,
	// },

	// {
	// 	path: demoPages.settings.subMenu.permissions.path,
	// 	element: <SETTINGS.PERMISSIONS />,
	// 	exact: true,
	// },

	{
		path: demoPages.settings.subMenu.gatesSettings.path,
		element: <SETTINGS.WORKSHOP_SETTINGS />,
		exact: true,
	},

	/**
	 * Translate
	 */

	{
		path: demoPages.translate.subMenu.languagesList.path,
		element: <TRANSLATE.LANGUAGES />,
		exact: true,
	},

	{
		path: demoPages.translate.subMenu.addLanguage.path,
		element: <TRANSLATE.NEW_LANGUAGES />,
		exact: true,
	},

	{
		path: '/languages/:code/edit',
		element: <TRANSLATE.EDIT_LANGUAGE />,
		exact: true,
	},

	{
		path: '/languages/:code/words',
		element: <TRANSLATE.LANGUAGE_WORDS />,
		exact: true,
	},

	/**
	 * Messages
	 */

	{
		path: demoPages.messages.path,
		element: <MESSAGES.MESSAGES_LIST />,

		exact: true,
	},
		

	{
		path: '/messages/:id',
		element: <MESSAGES.MESSAGE_DETAILS />,
		exact: true
	},

	/**
	 * Trackings
	 */

	{
		path: demoPages.trackings.path,
		element: <TRACKINGS.TRACKINGS_LIST />,
		exact: true
	},

];

export const websitePages = [
	{
		path: demoPages.pages.subMenu.listPages.path,
		element: <PAGES.LIST_PAGES />,
		exact: true,
	},
	{
		// path: demoPages.pages.subMenu.homePage.path,
		path: '/pages/home',
		element: <PAGES.HOME />,
		exact: true,
	},
	{
		path: demoPages.pages.subMenu.createPage.path,
		element: <PAGES.Create_Page />,
		exact: true,
	},
	
	
	{
		path: demoPages.pages.subMenu.createHeader.path,
		element: <PAGES.Create_Header />,
		exact: true,
	},
	{
		path: demoPages.pages.subMenu.createFooter.path,
		element: <PAGES.Create_Footer />,
		exact: true,
	},

	{
		// path: demoPages.pages.subMenu.aboutUs.path,
		path: '/pages/about-us',
		element: <PAGES.ABOUT_US />,
		exact: true,
	},

	{
		// path: demoPages.pages.subMenu.bilTuning.path,
		path: '/pages/bil-tuning',
		element: <PAGES.BilTuning />,
		exact: true,
	},

	{
		path: '/pages/contact-us',
		element: <PAGES.CONTACT_US />,
		exact: true,
	},

	{
		path: '/pages/folie',
		element: <PAGES.FOLIE />,
		exact: true,
	},
	{
		path: '/pages/about-us',
		element: <PAGES.ABOUT_US />,
		exact: true,
	},
	{
		path: '/pages/gallery',
		element: <PAGES.GALLERY />,
		exact: true,
	},
	{
		path: demoPages.fileManager.path,
		element: <FILES.FILE />,
		exact: true,
	},

	{
		path: '/pages/oppretting-lakkering',
		element: <PAGES.OPPRETTING_AND_LAKKERING />,
		exact: true,
	},

	{
		path: '/pages/our-services',
		element: <PAGES.OUR_SERVICES />,
		exact: true,
	},

	{
		path: '/pages/shop',
		element: <PAGES.SHOP />,
		exact: true,
	},

	{
		path: '/pages/terms-and-policy',
		element: <PAGES.TERMS_AND_POLICY />,
		exact: true,
	},
];

export const auth = [
	{
		path: '/login',
		element: <AUTH.LOGIN />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;
