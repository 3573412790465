import React, { useContext, useState, createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleErrorMessage as formatMessage } from '../helpers/handleErrorMessage';

const ErrorContext = createContext({})

const ErrorProvider = ({ children }: { children: React.ReactNode }) => {

    const [isTopError, setIsTopError] = useState(false);
    const [message, setMessage] = useState<any>(null)

    const handleErrorMessage = (err: any = 'Something went wrong') => {
        const message = formatMessage(err);
        setMessage(message);
    }

    const renderError = (): React.ReactNode => {
        if (!message) return null
        if (typeof message === 'string') {
            return <h5>{message}</h5>
        } else if (Array.isArray(message)) {
            return <ul
                style={{
                    margin: 0
                }}
            >
                {message.map(msg => (
                    <li key={msg}>{msg}</li>
                ))}
            </ul>
        } else if (typeof message === 'object') {
            return <ul
                style={{
                    margin: 0
                }}
            >
                {Object.values(message).map((msg: any) => (
                    <li key={msg}>{msg}</li>
                ))}
            </ul>
        }
    }

    const location = useLocation()

    useEffect(() => {
        setIsTopError(false)
        setMessage(null)
    }, [location])

    const showTopError = () => setIsTopError(true)
    const hideTopError = () => setIsTopError(false)

    return (
        <ErrorContext.Provider value={{
            isTopError,
            renderError,
            showTopError,
            hideTopError,
            handleErrorMessage
        }}>
            {children}
        </ErrorContext.Provider>
    )
}

export default ErrorProvider

interface IErrorValues {
    isTopError: boolean
    handleErrorMessage: (err?: any) => void
    renderError: () => React.ReactNode
    showTopError: () => void
    hideTopError: () => void
}

export const useErrorState = () => {
    return useContext(ErrorContext) as IErrorValues
}