import axios from "axios";
import axiosInstance from "./axios";

export const getLanguages = () => {
    return axiosInstance.get('/translations/languages')
}

export const getOneLanguage = (code: string) => {
    return axiosInstance.get(`/translations/languages/show/${code}`)
}

export const storeNewLanguage = (data: any) => {
    return axiosInstance.post('/translations/languages', data)
}

export const updateLanguageData = (id: any, data: any) => {
    return axiosInstance.put(`/translations/languages/${id}`, data)
}

export const deleteLanguage = (id: any) => {
    return axiosInstance.delete(`/translations/languages/${id}`);
}

export const getWordsList = (lang: string) => {
    return axiosInstance.get(`https://bpst.khotwa-tech.com/api/lang/${lang}`);
}

export const storeNewWord = (lang: string, data: any) => {
    return axiosInstance.post(`/translations/languages/key/store/${lang}`, data)
}

export const deleteWord = (data: any) => {
    return axiosInstance.post('/translations/languages/key/delete', data)
}

export const updateWord = (lang: string, data: any) => {
    return axiosInstance.put(`/translations/languages/key/update/${lang}`, data);
}