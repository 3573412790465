import { useMutation, useQuery } from '@tanstack/react-query';
import FileManager, { ItemView, Permissions } from 'devextreme-react/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { deleteFile, getAllFiles, updateFile, uploadFile } from '../../apis/fileManagerApi';
import PageSkelton from '../../components/PageSkelton';
import { useFileManager } from '../../contexts/FileManagerContext';
import { fileItems } from '../../data/files';
import useDarkMode from '../../hooks/useDarkMode';
// import 'devextreme/dist/css/dx.light.css';
import './file.css';

// Style.use()

const remoteProvider = new RemoteFileSystemProvider({
	endpointUrl: 'https://js.devexpress.com/Demos/Mvc/api/file-manager-file-system-images',
});

const addFile = (files: any[], id: number | false, newFile: any): any[] => {
	if (id === false) {
		return [...files, newFile];
	}

	return files.map((file) => {
		if (file.id === id) {
			return {
				...file,
				items: [...file.items, newFile],
			};
		}

		return {
			...file,
			items: addFile(file.items, id, newFile),
		};
	});
};

const deleteItem = (files: any[], id: any) => {
	return files.map((file) => {});

	// return deleteFile(files)
};

const FileTrial = () => {
	const { selectFile, setFiles, files } = useFileManager();
	const { themeStatus } = useDarkMode();

	const { isLoading, refetch } = useQuery(['files'], getAllFiles, {
		onSuccess(data) {
			setFiles(data.data);
		},
		refetchOnWindowFocus: false,
	});

	if (isLoading) {
		return <PageSkelton />;
	}

	return (
		<div className={`control-section dx-viewport ${themeStatus}`}>
			<FileManager
				height='500'
				notifications={{
					showPanel: false,
					showPopup: false
				}}
				// allowedFileExtensions={['.mp4', '.jpeg', '.jpg', '.svg', '.ico', '.png', '.webp', '.webm', '.avi', '.ogg']}
				rootFolderName="Root"
				// onItemDownloading={async (e) => {
				// 	// e.cancel = true;
				// 	console.log(e);
				// 	fetch(e.item.dataItem.url, {
				// 		// mode : 'no-cors',
				// 	  })
				// 		.then(response => response.blob())
				// 		.then(blob => {
				// 		let blobUrl = window.URL.createObjectURL(blob);
				// 		let a = document.createElement('a');
				// 		a.download = e.item.dataItem.url.replace(/^.*[\\\/]/, '');
				// 		a.href = blobUrl;
				// 		document.body.appendChild(a);
				// 		a.click();
				// 		a.remove();
				// 	  })

				// }}
				fileSystemProvider={files}
				onFileUploading={async (e) => {
					e.cancel = true;

					const formData = new FormData();

					const { fileData, destinationDirectory } = e;

					const parentID =
						destinationDirectory?.dataItem?.id &&
						typeof destinationDirectory?.dataItem?.id === 'number';

					if (parentID) {
						formData.append('parent', destinationDirectory.dataItem.id.toString());
					}

					formData.append('isDirectory', '0');
					formData.append('file', fileData);

					await uploadFile(formData);

					refetch()

				}}
				onDirectoryCreating={async (e) => {
					e.cancel = true;

					const { parentDirectory, name } = e;

					const formData = new FormData();

					const parentID = parentDirectory?.dataItem?.id;

					if (parentID) {
						formData.append('parent', parentID.toString());
					}

					formData.append('isDirectory', '1');
					formData.append('name', name);

					await uploadFile(formData);

					refetch()

					
				}}
				onItemRenaming={async (e) => {
					e.cancel = true;
					const ID = e.item.dataItem?.id;
					if (!ID) return;
					await updateFile(ID, e.newName);
					refetch();
				}}
				onItemDeleting={async (e) => {
					e.cancel = true;
					const ID = e.item.dataItem?.id;
					if (!ID) return;
					const { data } = await deleteFile(ID);
					refetch();
				}}
				onSelectedFileOpened={selectFile}
			>
				<Permissions
					// copy={false}
					// move={false}

					create={true}
					delete={true}
					rename={true}
					upload={true}
					// download={true}
				>
					hello
				</Permissions>
			</FileManager>
		</div>
	);
};

export default FileTrial;
