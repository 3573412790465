import React from 'react';
import { useFileManager } from '../../../contexts/FileManagerContext';
import FileTrial from '../../../pages/File/FileTrial';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';

interface IProps {
	// setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	// open: boolean;
}

const FileManagerModal = ({}: IProps) => {
	const { open, setOpen } = useFileManager();

	return (
		
		<Modal isOpen={open} setIsOpen={setOpen} size='xl'>
			<ModalHeader className='p-4' setIsOpen={setOpen}>
				<ModalTitle id='filemanager'>Files</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<FileTrial />
			</ModalBody>
		</Modal>
		
	);
};

export default FileManagerModal;
