import React from 'react';
import { demoPages } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers = [
	// { path: 'layoutMenu.blank.path', element: null, exact: true },
	{ path: '/login', element: null, exact: true },
	{ path: '/signup', element: null, exact: true },
	// { path: '*', element: <DefaultFooter /> },
	// { path: 'demoPages.knowledge.subMenu.grid.path', element: null, exact: true },
];

export default footers;
