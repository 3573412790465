import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { getSingleFile } from '../../apis/fileManagerApi';
import Avatar from '../../components/Avatar';

let x: React.VideoHTMLAttributes<HTMLVideoElement> = {};

interface IProps {
	id?: any;
	url?: any;
	avatar?: boolean;
	className?: string;
	size?: number;
	videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
	imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const FileIDReader = ({
	id,
	url,
	avatar,
	className = '',
	size,
	videoProps = {},
	imageProps = {},
}: IProps) => {
	    
    const { data } = useQuery(['files', id], () => getSingleFile(id), {
        refetchOnWindowFocus: false,
        enabled: (!isNaN(+id) && !url)
    });
    
    // if (isLoading) {
    //     return <></>
    // }

    const src = url || !isNaN(+id) ? data?.data?.url : id
    const ext = data?.data?.ext

    // const location = useLocation()

    // {console.log({
    //     location,
    //     avatar,
    //     ext
    // })}

    return src ? (
        ['png', 'jpg', 'jpep', 'webm', null, undefined].includes(ext) ? (
            avatar ? (
                <Avatar 
                    src={src}
                    className={className}
                    size={size}
                />
            ) : (
                <img 
                    {...imageProps}
                    src={src} 
                    className={className} 
                    alt={imageProps.alt} 
                />
            )
        ) : (
            ['mp4', '.webm', '.avi', '.ogg'].includes(ext) ? (
                <video
                    className={className}
                    {...videoProps}
                >
                    <source src={src} type="video/mp4" />
                    <source src={src} type="video/ogg" />
                </video>
            ) : (
                null
            )
        )
    ) : null
}

export default FileIDReader;
