import React from 'react'
import CommonDesc from '../common/other/CommonDesc'
import { useErrorState } from '../contexts/errorContext'
import Alert, { AlertHeading } from './bootstrap/Alert'
import Modal, { ModalBody, ModalHeader, ModalTitle } from './bootstrap/Modal'
import Icon from './icon/Icon'

// text #f35421
// bg #feeee9

const TopError = () => {

    const { isTopError, hideTopError, renderError } = useErrorState()

    return isTopError ? (
        <div
            style={{
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1500
            }}
        >
            <Alert
                color='danger'
                rounded={0}
                icon='Info'
            >
                
                <div className="">
                    
                    {renderError()}
                </div>

                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: 16,
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                    onClick={hideTopError}
                >
                    <Icon 
                        size={'2x'}
                        icon='Close'
                    />
                </div>
            </Alert>
        </div>
    ) : null
}

export default TopError