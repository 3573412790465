import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../components/Brand';
import Navigation, { NavigationLine } from '../../../components/Navigation/Navigation';
import { dashboardMenu, demoPages } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../components/Aside/Aside';
import AuthContext from '../../../contexts/authContext';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { setAccessToken } = useContext(AuthContext)

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const logout = () => {
		setAccessToken('')
	}

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardMenu} id='aside-dashboard' />
				<NavigationLine />
				<Navigation menu={demoPages} id='aside-demo-pages' />
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							// onClick={() => {
							// 	setDoc(!doc);
							// }}
							data-tour='documentation'
						>
							<span 
								className='navigation-link navigation-link-pill'
								onClick={logout}
							>
								<span className='navigation-link-info'>
									<Icon
										icon='Logout'
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{t('Logout')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
