import { SelectedFileOpenedEvent } from 'devextreme/ui/file_manager';
import React, { useState, useContext, createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FileManagerContext = createContext({});

interface IProps {
	children: React.ReactNode;
}

const FileManagerProvider = ({ children }: IProps) => {
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [fileSelected, setFileSelected] = useState<any>(null);

	const [files, setFiles] = useState([]);

	const selectFile = (e: SelectedFileOpenedEvent) => {
		setFileSelected(e.file);
		closeFileManager();
	};

	// const imageHanlder = () => {
	// 	setIsSelecting(true)
	// 	if (selectFromFileManager) {
	// 		selectFromFileManager()
	// 	} else {
	// 		openFileManager()
	// 	}
	// }

	const clearFileSelected = () => {
		setFileSelected(null);
	};

	function openFileManager() {
		setOpen(true);
	}

	function closeFileManager() {
		setOpen(false);
	}

	useEffect(() => {
		setFileSelected(null);
	}, [location]);

    return <FileManagerContext.Provider
        value={{
            files,
            setFiles,
            open,
            setOpen,
            openFileManager,
            closeFileManager,
            clearFileSelected,
            fileSelected,
            selectFile
        }}
    >
        {children}
    </FileManagerContext.Provider>
};

export default FileManagerProvider;

interface IValues {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	openFileManager: () => void;
	closeFileManager: () => void;
	fileSelected: any;
	selectFile: (e: SelectedFileOpenedEvent) => void;
	files: any[];
	setFiles: React.Dispatch<React.SetStateAction<any[]>>;
	clearFileSelected: () => void;
}

export const useFileManager = () => {
	return useContext(FileManagerContext) as IValues;
};
