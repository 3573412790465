import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../contexts/authContext'

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {

    const { accessToken } = useContext(AuthContext);

    if (!accessToken) {
        return <Navigate to='/login' replace />
    }

    return children
}

export default ProtectedRoute