import axiosInstance from './axios';

export const getAllFiles = () => {
    return axiosInstance.get('/files')
}

export const getSingleFile = (id: any) => {
    return axiosInstance.get(`/files/${id}`)
}

export const uploadFile = (formData: FormData) => {
    return axiosInstance.post('/files/store', formData)
}

export const deleteFile = (id: any) => {
    return axiosInstance.delete(`/files/delete/${id}`)
}

export const updateFile = (id: any, newName: string) => {
    return axiosInstance.post(`/files/update/${id}`, {
        name: newName
    })
}