import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://bpst.khotwa-tech.com/api',
})

axiosInstance.interceptors.request.use(
    async (config) => {
        if (config.headers) {
            config.headers.Authorization = `Bearer ${localStorage.getItem('facit_accessToken')}`
        }

        return config;
    },
    (error) => Promise.reject(error)
)

export default axiosInstance;