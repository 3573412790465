export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Home',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPages = {
	users: {
		id: 'users',
		text: 'Users',
		path: 'users',
		icon: 'PeopleOutline',
		subMenu: {
			usersList: {
				id: 'users-list',
				text: 'Users',
				path: '/users/list',
				icon: 'List',
			},
			usersBlacklist: {
				id: 'blacklist',
				text: 'Blacklist',
				path: 'users/blacklist',
				icon: 'GroupOff',
			},
			// createUser: {
			// 	id: 'create-user',
			// 	text: 'Create User',
			// 	path: 'users/create',
			// 	icon: 'PersonAdd',
			// },
		},
	},

	companies: {
		id: 'companies',
		text: 'Companies',
		path: 'companies',
		icon: 'EmojiTransportation',
		subMenu: {
			companiesList: {
				id: 'users-list',
				text: 'Companies List',
				path: 'companies/list',
				icon: 'List',
			},
			createCompany: {
				id: 'create-user',
				text: 'New',
				path: 'companies/create',
				icon: 'Add',
			},
		},
	},

	categories: {
		id: 'categories',
		text: 'Categories',
		path: 'categories',
		icon: 'Category',
		subMenu: {
			categoriesList: {
				id: 'categories-list',
				text: 'Categories',
				path: 'categories/list',
				icon: 'List',
			},
			createCategory: {
				id: 'create-category',
				text: 'New Category',
				path: 'categories/create',
				icon: 'Add',
			},
		},
	},

	vehicles: {
		id: 'vehicles',
		text: 'Vehicles Types',
		path: 'vehicles-types',
		icon: 'DirectionsCar',
		subMenu: {
			vehiclesList: {
				id: 'vehicles-list',
				text: 'Vehicles',
				path: 'vehicles-types/list',
				icon: 'List',
			},
			createVehicleType: {
				id: 'create-vehicles',
				text: 'Vehicles',
				path: 'vehicles-types/create',
				icon: 'Add',
			},
		},
	},

	services: {
		id: 'services',
		text: 'Services',
		path: 'services',
		icon: 'HomeRepairService',
		subMenu: {
			servicesList: {
				id: 'services-list',
				text: 'Services',
				path: 'services/list',
				icon: 'List',
			},
			createService: {
				id: 'create-service',
				text: 'New Service',
				path: 'services/create',
				icon: 'Add',
			},
		},
	},

	suggestion: {
		id: 'suggestions',
		text: 'Suggetions',
		path: 'suggetions',
		icon: 'AssistantPhoto',
		subMenu: {
			createSuggestion: {
				id: 'create-suggestion',
				text: 'New Suggestion',
				path: 'suggestions/create',
				icon: 'Add',
			},
			suggestionsList: {
				id: 'suggestions-list',
				text: 'Suggetions',
				path: 'suggestions/list',
				icon: 'List',
			},
		},
	},

	gates: {
		id: 'gates',
		text: 'Gates',
		path: 'gates',
		icon: 'CarRepair',
		subMenu: {
			gatesList: {
				id: 'gates-list',
				text: 'Gates',
				path: 'gates/list',
				icon: 'List',
			},
			createGate: {
				id: 'create-gate',
				text: 'New gate',
				path: 'gates/create',
				icon: 'Add',
			},
		},
	},

	booking: {
		id: 'booking',
		text: 'Booking',
		path: 'booking',
		icon: 'BookOnline',
		subMenu: {
			createBooking: {
				id: 'new-booking',
				text: 'New booking',
				path: 'booking/create',
				icon: 'Add',
			},
			bookingList: {
				id: 'booking-list',
				text: 'Booking list',
				path: 'booking/list',
				icon: 'List',
			},
		},
	},

	tax: {
		id: 'tax',
		text: 'Tax',
		path: 'tax',
		icon: 'AttachMoney',
		subMenu: {
			createTax: {
				id: 'new-tax',
				text: 'New tax',
				path: 'tax/create',
				icon: 'Add',
			},
			taxesList: {
				id: 'taxes-list',
				text: 'Taxes',
				path: 'tax/list',
				icon: 'List',
			},
		},
	},

	settings: {
		id: 'settings',
		text: 'Settings',
		path: 'settings',
		icon: 'SettingsApplications',
		subMenu: {
			contactInfo: {
				id: 'contact-info',
				text: 'Contact info',
				path: 'settings/contact-info',
				icon: 'ContactPhone',
			},
			aboutWebsite: {
				id: 'about-website',
				text: 'About website',
				path: 'settings/about-website',
				icon: 'Code',
			},
			paymentDetails: {
				id: 'payment-details',
				text: 'Payment',
				path: 'settings/payment',
				icon: 'Payment',
			},
			// reports: {
			// 	id: 'reports',
			// 	text: 'Reports',
			// 	path: 'settings/reports',
			// 	icon: 'Flag',
			// },
			gatesSettings: {
				id: 'workshop-setting',
				text: 'Workshop setting',
				path: 'settings/workshop-setting',
				icon: 'CarRepair',
			},
			bookingSetting: {
				id: 'booking-setting',
				text: 'Booking setting',
				path: 'settings/booking',
				icon: 'BookOnline',
			},
			aboutUsers: {
				id: 'about-users',
				text: 'About users',
				path: 'settings/about-users',
				icon: 'PeopleOutline',
			},
			notificationManagment: {
				id: 'notification-managment',
				text: 'Notification Managment',
				path: 'settings/notification-managment',
				icon: 'Sms',
			},

			accountManager: {
				id: 'account-manager',
				text: 'Account Manager',
				path: 'settings/account-manager',
				icon: 'AdminPanelSettings',
			},

			// permissions: {
			// 	id: 'permissions',
			// 	text: 'Permissions',
			// 	path: 'settings/permissions',
			// 	icon: 'VerifiedUser',
			// },
			

			// roles: {
			// 	id: 'roles',
			// 	text: 'Roles',
			// 	path: 'settings/roles',
			// 	icon: 'ManageAccounts',
			// },

			supervisors: {
				id: 'supervisors',
				text: 'Supervisors',
				path: 'settings/supervisors',
				icon: 'SupervisorAccount',
			},
		},
	},
	
	translate: {
		id: 'translate',
		text: 'Translate',
		path: 'languages',
		icon: 'Translate',
		subMenu: {
			languagesList: {
				id: 'languages-list',
				text: 'Languages',
				path: 'languages/list',
				icon: 'List',
			},
			addLanguage: {
				id: 'add-language',
				text: 'Add new Language',
				path: 'languages/add',
				icon: 'Add',
			},
		}
	},

	pages: {
		id: 'pages',
		text: 'Pages',
		path: 'pages',
		icon: 'Article',
		subMenu: {
			listPages: {
				id: 'list-pages',
				text: 'Pages List',
				path: '/pages/list',
				icon: 'List',
			},
			createPage: {
				id: 'create-page',
				text: 'New page',
				path: 'pages/create',
				icon: 'DashboardCustomize',
			},
			createHeader: {
				id: 'create-header',
				text: 'Header creation',
				path: 'pages/header',
				icon: 'Tab',
			},
			createFooter: {
				id: 'create-footer',
				text: 'Footer creation',
				path: 'pages/footer',
				icon: 'CallToAction',
			},
			// homePage: {
				// id: 'home-page',
				// text: 'Home page',
				// icon: 'Home',
				// path: '/pages/home',
			// },
			// aboutUs: {
			// 	id: 'about-us',
			// 	text: 'About us',
			// 	path: 'pages/about-us',
			// 	icon: 'Info',
			// },
			// contactUs: {
			// 	id: 'contact-us',
			// 	text: 'Contact us',
			// 	path: 'pages/contact-us',
			// 	icon: 'Contacts',
			// },
			// gallery: {
			// 	id: 'gallery',
			// 	text: 'Gallery',
			// 	path: 'pages/gallery',
			// 	icon: 'Image',
			// },
			// ourServices: {
			// 	id: 'our-services',
			// 	text: 'Our services',
			// 	path: 'pages/our-services',
			// 	icon: 'HomeRepairService',
			// },
			// termsAndPolicy: {
			// 	id: 'terms-and-policy',
			// 	text: 'Terms and policy',
			// 	path: 'pages/terms-and-policy',
			// 	icon: 'Policy',
			// },
			// shop: {
			// 	id: 'shop',
			// 	text: 'Shop',
			// 	path: 'pages/shop',
			// 	icon: 'Shop',
			// },
			// bilTuning: {
			// 	id: 'bit-tuning',
			// 	text: 'Bil Tuning',
			// 	path: 'pages/bil-tuning',
			// 	icon: 'Money',
			// },
			// opprettingAndLakkering: {
			// 	id: 'oppretting',
			// 	text: 'Oppretting',
			// 	path: 'pages/oppretting-lakkering',
			// 	icon: 'Code',
			// },
			// folie: {
			// 	id: 'folie',
			// 	text: 'Folie',
			// 	path: 'pages/folie',
			// 	icon: 'Http',
			// },
		},
	},
	subscription: {
		id: 'subscription',
		text: 'Subscription',
		path: 'settings/subscription',
		icon: 'Flag',
		subMenu: null
	},

	trackings: {
		id: 'trackings',
		text: 'Trackings',
		path: 'trackings',
		icon: 'Timeline',
		subMenu: null
	},

	fileManager: {
		id: 'fileManager',
		text: 'File Manager',
		path: 'files/manager',
		icon: 'DriveFileMove',
	},

	messages: {
		id: 'messages',
		text: 'Messages',
		path: 'messages',
		icon: 'Message',
		subMenu: null
	},
};
