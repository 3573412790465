import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { getUserDataByAccessToken } from '../helpers/getUserData';

interface IUser {
	email: string, id: string, name: string, image: string
}

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: IUser | null;
	setUserData: React.Dispatch<React.SetStateAction<IUser | null>>
	accessToken: string
	setAccessToken: React.Dispatch<React.SetStateAction<string>>
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');

	const [userData, setUserData] = useState<IUser| null>(JSON.parse(localStorage.getItem('facit_user') || '{}') || null);
	const [accessToken, setAccessToken] = useState(localStorage.getItem('facit_accessToken') || '');
	
	
	useEffect(() => {
		if (accessToken) {
			// setUserData(getUserDataByAccessToken(accessToken));
		} else {
			// setUserData({});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			setUserData,
			accessToken,
			setAccessToken
		}),
		[user, userData, accessToken],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
