import React from 'react'
import Card, { CardBody } from './bootstrap/Card'
import Page from './Layout/Page'
import PageWrapper from './Layout/PageWrapper'
import SubHeader from './Layout/SubHeader'

const PageSkelton = () => {
    return (
        <PageWrapper>
            <SubHeader>
                <div />
            </SubHeader>
            <Page>
                <div className='row h-100'>
                    <div className='col-lg-6'>
                        <Card stretch>
                            <CardBody>
                                <div />
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-6'>
                        <Card stretch='semi'>
                            <CardBody>
                                <div />
                            </CardBody>
                        </Card>
                        <Card stretch='semi'>
                            <CardBody>
                                <div />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    )
}

export default PageSkelton